.prodListTableBox {
  background-color: #00000010;
}

.textTest {
  padding: 1px 1px;
  max-width: 50px;
  margin: 0px !important;
  font-size: 0.7rem;
  border-radius: 2px !important;
  border: 1px lightgray;
}
.prodListMainBox {
  width: 90%;
}

.hasQtd {
  color: rgba(17, 170, 20, 0.95);
  padding-left: 10%;
  font-weight: bolder;
}

.hasQtd input {
  color: rgba(17, 170, 20, 0.95);
  padding-left: 10%;
  font-weight: bolder;
  width: 100%;
  border: none;
  outline: none;
}

.hasNoQtd input {
  color: rgba(170, 17, 17, 0.75);
  padding-left: 10%;
  font-weight: bolder;
  width: 100%;
  border: none;
  outline: none;
}

.descInput input {
  color: rgba(170, 17, 17, 0.75);
  padding-left: 10%;
  font-weight: bolder;
  width: 100%;
  border: none;
  outline: none;
}

.hasQtd input:focus,
.hasNoQtd input:focus,
.descInput input:focus {
  border-bottom: 1px solid #dedede;
}

.hasNoQtd {
  color: rgba(170, 17, 17, 0.75);
  padding-left: 10%;
  font-weight: bolder;
}

.priceWithQtd span {
  background-color: #cbe0ff;
  border-radius: 2.5px;
  padding: 2px 10px;
  color: #113468;
  font-weight: bolder;
}

.descTag {
  background-color: #94ffdc78;
  border-radius: 2.5px;
  padding: 2px 10px;
  color: #20984f;
  font-weight: bolder;
  border-radius: 25px;
}

.multTag {
  background-color: #ffa96ae8;
  border-radius: 2.5px;
  padding: 2px 10px;
  color: #ffffff;
  font-weight: normal;
  border-radius: 25px;
}
.comboTag {
  background-color: #ffe693;
  border-radius: 2.5px;
  padding: 2px 10px;
  color: #000;
  font-weight: light;
  border-radius: 25px;
}

.codprodCheck {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.prodTags {
  width: 100%;
  display: flex !important;
  flex-direction: row !important;
  gap: 10px !important;
  padding-top: 5px;
}

.prodImage {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #00000010;
}

.prodImage img {
  max-width: 50px;
  max-height: 50px;
}
