.newPedMainBox{
    height: 100%;
    width: 100%;
    padding: 10px;
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: #4d5156;
  }


  .newPedPaymentData{
    background-color: #FFF;
    width: 90%;
    max-width: 95%;
    height: 12vh;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }



  .paymentInputBox{
    display: inline-flex;
    flex-direction: column;
    gap: 5px;
  }


  .paymentInputBox input, .paymentInputBox select{
  
    background: transparent;
    border: none;
    padding-left: 10px;
    padding-right: 10px;

    font-size: 1rem;

    color: #555555;

    color: #000;
    background-color: #FAFAFA;
    box-shadow: rgba(0, 0, 0, 0.175) 0px 1px 3px 0px, rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
    
  }
  
  .paymentInputBox input:focus, .paymentInputBox select:focus{
      border: none;
      outline: none;
    }

    .paymentInputBox label{

    }


    .clientInfoDados{
      line-height: 1,75rem;
      line-height: 2rem;
      margin: 0 20px;
      font-size: 1rem;
    }

    .clientPedData{
      width: 70%;
      display: flex;
      gap: 10px;
    }


    .pedTotalData{
      height: 95%;
      width: 30%;
      padding: 5px 0px;
    }
    .pedTotalDataHeader{
      background-color: #ebebeb;
      height: 40%;
      width: 100%;
      /* padding: 5px; */
      display: flex;
      justify-content: space-evenly;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      font-weight: 600;
    }
    .pedTotalDataValues{
      background-color: #f9f9f9;
      height: 40%;
      width: 100%;
      /* padding: 5px; */
      display: flex;
      justify-content: space-evenly;
    }
    .pedTotalDataHeader span, .pedTotalDataValues span {
     width: 25%;
     text-align: center;
    }

    .totalqtd{
      /* background-color: #090908; */
      background-color: #606062;
      color: #FFF;
      margin: 2px
    }