.button {
  background-color: #228F6B !important;
  color: #FFF !important;

  &:hover {
    background-color: #176148 !important;
  }
}

.versionContainer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  .version {
    text-align: center;
    color: #111b2169;
  }
}
